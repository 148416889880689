// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-areas-de-practica-de-js": () => import("./../../../src/pages/areas-de-practica.de.js" /* webpackChunkName: "component---src-pages-areas-de-practica-de-js" */),
  "component---src-pages-areas-de-practica-en-js": () => import("./../../../src/pages/areas-de-practica.en.js" /* webpackChunkName: "component---src-pages-areas-de-practica-en-js" */),
  "component---src-pages-areas-de-practica-fr-js": () => import("./../../../src/pages/areas-de-practica.fr.js" /* webpackChunkName: "component---src-pages-areas-de-practica-fr-js" */),
  "component---src-pages-areas-de-practica-js": () => import("./../../../src/pages/areas-de-practica.js" /* webpackChunkName: "component---src-pages-areas-de-practica-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-de-js": () => import("./../../../src/pages/nosotros.de.js" /* webpackChunkName: "component---src-pages-nosotros-de-js" */),
  "component---src-pages-nosotros-en-js": () => import("./../../../src/pages/nosotros.en.js" /* webpackChunkName: "component---src-pages-nosotros-en-js" */),
  "component---src-pages-nosotros-fr-js": () => import("./../../../src/pages/nosotros.fr.js" /* webpackChunkName: "component---src-pages-nosotros-fr-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */)
}

